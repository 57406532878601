export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_FAILED = 'AUTH_FAILED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'

export const LOAD_USER = 'LOAD_USER'
export const LOGOUT = 'LOGOUT'

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const SET_RESET_PASSWORD_SUCCESS = 'SET_RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'

export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE'
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
export const SET_WARNING_MESSAGE = 'SET_WARNING_MESSAGE'
export const SET_INFO_MESSAGE = 'SET_INFO_MESSAGE'

export const FETCH_COMPANY_DETAILS_REQUEST = 'FETCH_COMPANY_DETAILS_REQUEST'
export const FETCH_COMPANY_DETAILS_SUCCESS = 'FETCH_COMPANY_DETAILS_SUCCESS'
export const EDIT_COMPANY_DETAILS_REQUEST = 'EDIT_COMPANY_DETAILS_REQUEST'
export const EDIT_COMPANY_DETAILS_SUCCESS = 'EDIT_COMPANY_DETAILS_SUCCESS'

export const FETCH_CURRENCY_CONVERSION_RATE_REQUEST =
  'FETCH_CURRENCY_CONVERSION_RATE_REQUEST'
export const FETCH_CURRENCY_CONVERSION_RATE_SUCCESS =
  'FETCH_CURRENCY_CONVERSION_RATE_SUCCESS'
export const FETCH_CURRENCY_CONVERSION_RATE_FAILED =
  'FETCH_CURRENCY_CONVERSION_RATE_FAILED'

export const SET_CURRENCY = 'SET_CURRENCY'

export const FETCH_RETURNS_REQUEST = 'FETCH_RETURNS_REQUEST'
export const POST_RETURNS_REQUEST = 'POST_RETURNS_REQUEST'
export const FETCH_RETURNS_SUCCESS = 'FETCH_RETURNS_SUCCESS'
export const FETCH_RETURNS_FAILED = 'FETCH_RETURNS_FAILED'
export const FETCH_RETURN_REQUEST_DETAILS_REQUEST =
  'FETCH_RETURN_REQUEST_DETAILS_REQUEST'
export const FETCH_RETURN_REQUEST_DETAILS_SUCCESS =
  'FETCH_RETURN_REQUEST_DETAILS_SUCCESS'
export const FETCH_RETURN_REQUEST_DETAILS_FAILED =
  'FETCH_RETURN_REQUEST_DETAILS_FAILED'
export const SELECT_RETURN_REQUEST = 'SELECT_RETURN_REQUEST'
