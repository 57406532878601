import {
  SET_SUCCESS_MESSAGE,
  SET_ERROR_MESSAGE,
  SET_WARNING_MESSAGE,
  SET_INFO_MESSAGE,
} from '../actions/types'

const initialState = {
  successToastMessage: null,
  errorToastMessage: null,
  warningToastMessage: null,
  infoToastMessage: null,
}

export default function (state = initialState, action) {
  const {type, message} = action
  switch (type) {
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successToastMessage: message,
      }
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        errorToastMessage: message,
      }
    case SET_WARNING_MESSAGE:
      return {
        ...state,
        warningToastMessage: message,
      }
    case SET_INFO_MESSAGE:
      return {
        ...state,
        infoToastMessage: message,
      }
    default:
      return state
  }
}
