import React from 'react'
import {Alert} from 'antd'
import {useSelector} from 'react-redux'

const Toast = () => {
  const {
    successToastMessage,
    errorToastMessage,
    warningToastMessage,
    infoToastMessage,
  } = useSelector((state) => state.alerts)
  return (
    <>
      {successToastMessage && (
        <Alert message={successToastMessage} type="success" showIcon closable />
      )}
      {errorToastMessage && (
        <Alert message={errorToastMessage} type="error" showIcon closable />
      )}
      {warningToastMessage && (
        <Alert message={warningToastMessage} type="warning" showIcon closable />
      )}
      {infoToastMessage && (
        <Alert message={infoToastMessage} type="info" showIcon closable />
      )}
    </>
  )
}

export default Toast
