import {
  FETCH_COMPANY_DETAILS_REQUEST,
  FETCH_COMPANY_DETAILS_SUCCESS,
  FETCH_CURRENCY_CONVERSION_RATE_SUCCESS,
  FETCH_CURRENCY_CONVERSION_RATE_FAILED,
  SET_CURRENCY,
} from '../actions/types'

const initialState = {
  loading: false,
  companyDetails: null,
  currencyConversionRate: null,
  currencyCode: null,
}

export default function (state = initialState, action) {
  const {type, payload} = action

  switch (type) {
    case FETCH_COMPANY_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        companyDetails: payload,
      }
    case FETCH_CURRENCY_CONVERSION_RATE_SUCCESS:
      return {
        ...state,
        currencyConversionRate: payload,
      }
    case FETCH_CURRENCY_CONVERSION_RATE_FAILED:
      return {
        ...state,
        currencyConversionRate: null,
      }
    case SET_CURRENCY:
      return {
        ...state,
        currencyCode: payload,
      }
    default:
      return state
  }
}
