import {
  FETCH_RETURNS_REQUEST,
  FETCH_RETURNS_SUCCESS,
  FETCH_RETURNS_FAILED,
  FETCH_RETURN_REQUEST_DETAILS_REQUEST,
  FETCH_RETURN_REQUEST_DETAILS_SUCCESS,
  FETCH_RETURN_REQUEST_DETAILS_FAILED,
  SELECT_RETURN_REQUEST,
  POST_RETURNS_REQUEST,
} from '../actions/types'

const initialState = {
  loading: false,
  returnRequests: [],
  totalPending: null,
  totalApproved: null,
  totalCompleted: null,
  returnRequestDetails: null,
  selectedRequest: null,
}

export default function (state = initialState, action) {
  const {type, payload} = action

  switch (type) {
    case FETCH_RETURNS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case POST_RETURNS_REQUEST:
      return {
        ...state,
        loading: false,
      }
    case FETCH_RETURNS_SUCCESS:
      return {
        ...state,
        loading: false,
        returnRequests: payload.requests,
        totalCompleted: payload.totalCompleted,
        totalPending: payload.totalPending,
        totalApproved: payload.totalApproved,
      }
    case FETCH_RETURNS_FAILED:
      return {
        ...state,
        loading: false,
        returnRequests: [],
        // totalCompleted: null,
        // totalPending: null,
      }
    case FETCH_RETURN_REQUEST_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_RETURN_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        returnRequestDetails: payload,
      }
    case FETCH_RETURN_REQUEST_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        returnRequestDetails: null,
      }
    case SELECT_RETURN_REQUEST:
      return {
        ...state,
        selectedRequest: payload,
      }
    default:
      return state
  }
}
