import {
  AUTH_FAILED,
  LOGIN_SUCCESS,
  AUTH_REQUEST,
  LOAD_USER,
  LOGOUT,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  RESET_PASSWORD_REQUEST,
  SET_RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
} from '../actions/types'

const initialState = {
  access_token: localStorage.getItem('dkm_sa_access_token'),
  isAuth: null,
  loading: false,
  user: null,
  userProfile: null,
  isSubmittedSuccessfully: false,
}

export default function (state = initialState, actions) {
  const {type, payload} = actions

  switch (type) {
    case LOAD_USER:
      return {
        ...state,
        user: payload,
        isAuth: true,
        loading: false,
      }
    case AUTH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOGIN_SUCCESS:
      localStorage.setItem('dkm_sa_access_token', payload.access_token)
      return {
        ...state,
        ...payload,
        isAuth: true,
        loading: false,
      }
    case AUTH_FAILED:
    case LOGOUT:
      localStorage.removeItem('dkm_sa_access_token')
      return {
        ...state,
        access_token: null,
        isAuth: false,
        loading: false,
        user: null,
      }
    case FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FORGOT_PASSWORD_SUCCESS:
    case FORGOT_PASSWORD_FAILED:
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
      }
    case SET_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubmittedSuccessfully: payload,
      }

    default:
      return state
  }
}
