import React from 'react'
import {Router, Switch, Route} from 'react-router-dom'
import {Provider} from 'react-redux'

import store from './store'
import {history} from './utils/history'
import AuthLayoutRoute from './Layouts/AuthLayout/AuthLayoutRoute'
import Toast from './Components/Generic/Toast'

const Login = React.lazy(() => import('./Pages/Login'))
const ForgotPassword = React.lazy(() => import('./Pages/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./Pages/ResetPassword'))
const DefaultLayout = React.lazy(() =>
  import('./Layouts/DefaultLayout/Container'),
)

const termsOfService = React.lazy(() =>
  import('./Pages/TermsOfService/termsOfService'),
)
const privacyPolicy = React.lazy(() =>
  import('./Pages/PrivacyPolicy/privacyPolicy'),
)

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
)

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Toast />
        <React.Suspense fallback={loading()}>
          <Switch>
            <AuthLayoutRoute exact path="/login" component={Login} />
            <AuthLayoutRoute
              exact
              path="/forgot-password"
              component={ForgotPassword}
            />
            <AuthLayoutRoute
              exact
              path="/reset-password"
              component={ResetPassword}
            />

            <Route exact path="/terms-of-service" component={termsOfService} />

            <Route exact path="/privacy-policy" component={privacyPolicy} />

            <Route path="/" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </Router>
    </Provider>
  )
}

export default App
