import {combineReducers} from 'redux'
import auth from './auth'
import alerts from './alerts'
import companies from './companies'
import returns from './returns'

export default combineReducers({
  auth,
  alerts,
  companies,
  returns,
})
